import React from 'react'
import Footer from '../components/public/shared/Footer'
import Link from 'next/link'

interface ContactProps {
	authenticated: boolean
}

class Page404 extends React.Component<ContactProps> {
	constructor(props: any) {
		super(props)
	}

	render() {
		return (
			<div className="wrapper bg-white min-h-screen">
				<main className="pt-20">
					<div className="relative py-10">
						<div className="container mx-auto px-4 relative z-10">
							<div className="max-w-lg bg-white mx-auto rounded-lg overflow-hidden pt-6">
								<h1 className="font-sans font-bold text-3xl mb-3 border-b px-8 py-4 text-center">
									Page not found
								</h1>

								<div className="px-8 pt-6 pb-8 mb-4 text-center">
									<p className="text-lg mb-4">
										Unfortunately the page you were looking for could not be
										found.
									</p>
									<Link href="/" passHref>
										<button className="bg-blue-600 text-xl text-white hover:bg-blue-700">
											Back to home
										</button>
									</Link>
								</div>
							</div>
						</div>
					</div>
				</main>
				<Footer />
			</div>
		)
	}
}

export default Page404
